<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Update Student</h1>
      </div>
      <form @submit.prevent="updateSchool">
        <div>
          <Input
            :data="getStudentData().first_name"
            :validationType="'string'"
          />
          <Input
            :data="getStudentData().last_name"
            :validationType="'string'"
          />
          <Input :data="getStudentData().grade" :validationType="'char'" />
          <Input :data="getStudentData().score" :validationType="'number'" />
          <Input
            :data="getStudentData().password"
            :type="'password'"
            :validationType="'password'"
          />
        </div>
        <FilledButton text="Update" />
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "../FilledButton.vue";
import Input from "../Input.vue";

export default {
  name: "AddStudent",
  components: {
    FilledButton,
    Input,
  },
  props: {
    studentData: Object,
  },
  data() {
    return {
      formData: {
        first_name: {
          data: this.studentData.first_name,
          label: "First Name",
        },
        last_name: {
          data: this.studentData.last_name,
          label: "Last Name",
        },
        grade: {
          data: this.studentData.grade,
          label: "Grade",
        },
        score: {
          data: this.studentData.score,
          label: "Score",
        },
        password: {
          data: this.studentData.password,
          label: "Password",
        },
      },
    };
  },
  methods: {
    async updateSchool() {
      const data = {
        first_name: this.formData.first_name.data,
        last_name: this.formData.last_name.data,
        grade: this.formData.grade.data,
        score: this.formData.score.data,
        password: this.formData.password.data,
      };
      const res = await fetch(
        process.env.VUE_APP_URL + "students/" + this.studentData.username,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify(data),
        },
      );
      const body = await res.json();
      if (res.status !== 200) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
    getStudentData() {
      this.formData.first_name.data = this.studentData.first_name;
      this.formData.last_name.data = this.studentData.last_name;
      this.formData.grade.data = this.studentData.grade;
      this.formData.score.data = this.studentData.score;
      this.formData.password.data = this.studentData.password;
      return this.formData;
    },
  },
};
</script>
