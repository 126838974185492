<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Upload</h1>
      </div>
      <form @submit.prevent="upload" enctype="multipart/form-data">
        <div style="padding: 12px; font-size: 22px">
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            ref="file"
          />
        </div>
        <FilledButton text="Upload" />
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "./FilledButton.vue";

export default {
  name: "Upload",
  components: {
    FilledButton,
  },
  props: { url: String },
  methods: {
    async upload() {
      const file = new FormData();
      file.append("file", this.$refs.file.files[0]);
      const res = await fetch(this.url, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
        method: "POST",
        body: file,
      });
      const body = await res.json();
      if (res.status !== 200) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
  },
};
</script>
