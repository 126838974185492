<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Update School</h1>
      </div>
      <form @submit.prevent="updateSchool">
        <div>
          <Input :data="getSchoolData().name" :validationType="'string'" />
          <Input
            :data="getSchoolData().username"
            :validationType="'username'"
          />
          <Input
            :data="getSchoolData().password"
            :type="'password'"
            :validationType="'password'"
          />
        </div>
        <FilledButton text="Update" />
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "../FilledButton.vue";
import Input from "../Input.vue";

export default {
  name: "AddStudent",
  components: {
    FilledButton,
    Input,
  },
  props: {
    id: Number,
    schoolData: Object,
  },
  data() {
    return {
      formData: {
        name: {
          data: this.schoolData.name,
          label: "Name",
        },
        username: {
          data: this.schoolData.username,
          label: "Username",
        },
        password: {
          data: this.schoolData.password,
          label: "Password",
        },
      },
    };
  },
  methods: {
    async updateSchool() {
      const data = {
        name: this.formData.name.data,
        score: this.formData.username.data,
        password: this.formData.password.data,
      };
      const res = await fetch(process.env.VUE_APP_URL + "schools/" + this.id, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify({
          username: data.username,
          password: data.password,
          name: data.name,
        }),
      });
      const body = await res.json();
      if (res.status !== 200) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
    getSchoolData() {
      this.formData.name.data = this.schoolData.name;
      this.formData.username.data = this.schoolData.username;
      this.formData.password.data = this.schoolData.password;
      return this.formData;
    },
  },
};
</script>
