<template>
  <button>{{ text }}</button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
  },
};
</script>

<style scoped>
button {
  background: #efefef;
  border: 1px solid white;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  padding: 0.7rem 2rem;
  cursor: pointer;
  font-family: inherit;
  margin: 0.6rem;
  width: 80%;
}
button:active {
  transform: scale(0.98);
  color: white;
  background-color: #f27300;
}
button:hover {
  border: 1px solid #f27300;
}

@media screen and (max-width: 1024px) {
  button {
    font-size: 1rem;
  }
}
</style>
