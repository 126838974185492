<template>
  <div class="container">
    <div class="row">
      <label>{{ data.label }}</label>
      <input
        v-model="data.data"
        :type="type"
        :placeholder="data.label"
        required
        :pattern="pattern"
        autocomplete="off"
        @change="onValid"
        @invalid="onInvalid"
        @focus="onFocus"
      />
    </div>
    <p style="color: red; display: block">{{ occurredInvalid }}</p>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    type: String,
    data: Object,
    validationType: String,
  },
  data() {
    return {
      occurredInvalid: "",
      pattern: null,
    };
  },
  methods: {
    onInvalid() {
      if (this.data.data !== "") {
        switch (this.validationType) {
          case "password":
            this.occurredInvalid =
              "Must contain 8 or more characters that are of at least one number, and one uppercase and lowercase letter";
            break;
          case "username":
            this.occurredInvalid =
              "Must contain 5 or more letters , numbers, or the underscore are allowed";
            break;
          case "string":
            this.occurredInvalid =
              "Must contain 3 or more just letters are allowed";
            break;
          case "char":
            this.occurredInvalid =
              "Only CAPITAL CASE characters and numbers are allowed maximum length 3";
            break;
          case "number":
            this.occurredInvalid = "Only numbers are allowed";
            break;
          default:
            this.occurredInvalid = "";
        }
      } else {
        this.occurredInvalid = "Required Filed";
      }
    },
    onValid() {
      this.occurredInvalid = "";
    },

    onFocus() {
      switch (this.validationType) {
        case "password":
          this.pattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$";
          break;
        case "username":
          this.pattern = "[A-Za-z0-9_]{5,15}$";
          break;
        case "string":
          this.pattern = "(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+{4,}";
          break;
        case "char":
          this.pattern = "[A-Z-0-9].{1,3}$";
          break;
        case "number":
          this.pattern = "[0-9].{1,}$";
          break;
        default:
          this.pattern = null;
      }
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.container {
  margin: 1.2rem;
}

.container p {
  margin-left: 3rem;
}

label {
  flex: 2;
}

input {
  flex: auto;
  padding: 0.6rem;
  text-decoration: none;
  border: none;
  font-size: 1rem;
  border-radius: 0.4rem;
  background-color: #efefef;
}
input:focus {
  outline: 1px solid #f27300 !important;
  border: none;
}
</style>
