<template>
  <div class="container">
    <aside>
      <h1>ACT Subject Jordan</h1>
      <h3>{{ userData().role }}</h3>
      <h3>{{ userData().username }}</h3>
      <div class="sidebar">
        <!-- <router-link to="/dashboard">
          <Button :text="'Dashboard'" />
        </router-link> -->
        <router-link to="/dashboard/students">
          <Button :text="'Students'" />
        </router-link>
        <router-link
          v-show="userData().role === 'admin'"
          to="/dashboard/schools"
        >
          <Button :text="'Schools'" />
        </router-link>
      </div>
      <p>&copy;2021 American Academy</p>
    </aside>
    <main>
      <Navbar />
      <router-view />
    </main>
  </div>
</template>

<script>
import parseJwt from "../helper/parseJwt";
import Button from "../components/Button.vue";
import Navbar from "../components/Navbar.vue";

export default {
  name: "Dashboard",
  components: {
    Button,
    Navbar,
  },
  methods: {
    userData() {
      const jwtdata = parseJwt(localStorage.token);
      return {
        username: jwtdata.username,
        role: jwtdata.role,
      };
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

aside {
  color: #000;
  background-color: #fff;
  width: 20%;
  height: 100vh;
  text-align: center;
  padding: 0.9rem;
  box-sizing: border-box;
  position: relative;
}
aside h1 {
  color: #f27300;
  font-size: 2.5rem;
  font-weight: bold;
}
aside h3 {
  margin-top: 1.5rem;
}

aside p {
  position: absolute;
  bottom: 0px;
  font-size: 0.75rem;
}

.sidebar {
  margin-top: 32%;
}

main {
  flex: 1;
  overflow: auto;
  padding: 1.5rem;
  height: 100vh;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  aside h1 {
    font-size: 1.6rem;
  }
  aside h3 {
    font-size: 0.8rem;
  }
  .sidebar {
    margin-top: 5rem;
  }
}
</style>
