<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Add School</h1>
      </div>
      <form @submit.prevent="add">
        <div>
          <Input :data="formData.name" :validationType="'string'" />
          <Input :data="formData.username" :validationType="'username'" />
          <Input
            :data="formData.password"
            :type="'password'"
            :validationType="'password'"
          />
          <label for="name"></label>
        </div>
        <FilledButton text="Add" />
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "../FilledButton.vue";
import Input from "../Input.vue";
import { reactive } from "vue";

export default {
  name: "AddSchool",
  components: {
    Input,
    FilledButton,
  },
  data: () => {
    const formData = reactive({
      username: {
        data: "",
        label: "Username",
      },
      password: {
        data: "",
        label: "Password",
      },
      name: {
        data: "",
        label: "Name",
      },
    });

    return {
      formData,
    };
  },
  methods: {
    async add() {
      const data = {
        name: this.formData.name.data,
        username: this.formData.username.data,
        password: this.formData.password.data,
      };
      const res = await fetch(process.env.VUE_APP_URL + "schools/add", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const body = await res.json();
      if (res.status !== 201) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
  },
};
</script>
