<template>
  <nav>
    <h1>ACT Subject Jordan</h1>
    <FilledButton class="right" @click="logout()" :text="'Logout'" />
  </nav>
</template>

<script>
import FilledButton from "../components/FilledButton.vue";

export default {
  name: "Navbar",
  components: {
    FilledButton,
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #222;
  box-sizing: border-box;
  width: 100%;
  padding: 1.3rem;
  overflow: hidden;
  border-radius: 0.7rem;
}

.right {
  float: right;
}
h1 {
  color: #f27300;
  font-size: 1.6rem;
  font-weight: bold;
  display: inline-block;
}
</style>
