<template>
  <div class="container">
    <form @submit.prevent="submit">
      <h1>ACT Subject Jordan</h1>
      <div class="wrapper">
        <input
          v-model="data.username"
          type="text"
          name="username"
          placeholder="Username"
          required
          autocomplete="off"
        />
        <input
          v-model="data.password"
          type="password"
          name="password"
          id="password"
          placeholder="Password"
          required
        />
      </div>
      <br />
      <Button :text="'Login'" />
    </form>
  </div>
  <p>&copy;2021 American Academy</p>
</template>

<script>
import Button from "@/components/Button.vue";
import { reactive } from "vue";

export default {
  name: "Login",
  components: {
    Button,
  },

  data: () => {
    const data = reactive({
      username: "",
      password: "",
    });

    return {
      data,
    };
  },
  methods: {
    async submit() {
      const res = await fetch(process.env.VUE_APP_URL + "session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.data),
      });

      const body = await res.json();
      if (res.status !== 200) this.$toast.error(body.ERROR);
      else {
        this.$toast.success("Login Successfully");
        localStorage.setItem("token", body.value[0].token);
        this.$router.push("/dashboard");
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
  margin-top: 10%;
}
form {
  display: inline-block;
  background-color: #fff;
  width: 25%;
  height: 360px;
  text-align: center;
  padding: 40px;
  border-radius: 13px;
}
h1 {
  color: #f27300;
}
.wrapper {
  margin-top: 42px;
}
input {
  margin-block: 20px;
  padding: 12px;
  width: 80%;
  text-decoration: none;
  border: none;
  font-size: 18px;
  border-radius: 7px;
  background-color: #efefef;
}
input:focus {
  outline: 1px solid #f27300 !important;
  border: none;
}
p {
  position: absolute;
  bottom: 0px;
  font-size: 0.75rem;
  color: white;
}
</style>
