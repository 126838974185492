<template>
  <table>
    <thead>
      <tr>
        <th v-for="th in tableHeader" :key="th">
          <span>{{ th.text }}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="td in tableData"
        @click="selected(td.id)"
        :key="td"
        :class="{ highlight: td.id == selectedId }"
      >
        <td>{{ td.id }}</td>
        <td>{{ td.name }}</td>
        <td>{{ td.username }}</td>
        <td>{{ td.password }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table",
  props: {
    columns: Array,
    entries: Array,
  },
  data() {
    return {
      selectedId: -1,
    };
  },
  methods: {
    selected(id) {
      this.selectedId == id ? (this.selectedId = -1) : (this.selectedId = id);
      this.$emit("selected", this.selectedId);
    },
  },
  computed: {
    tableHeader() {
      return this.columns || [];
    },
    tableData() {
      return this.entries || [];
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  text-align: right;
}

table {
  border-collapse: collapse;
  margin: 1.5rem 0;
  font-size: 1.2rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0.6rem;
}
thead tr {
  background-color: #f27300;
  text-align: left;
  color: white;
  font-weight: bold;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.3);
}

th,
td {
  padding: 0.6rem 0.8rem;
  text-align: left;
}
tbody {
  background-color: #fff;
}
tbody tr:nth-of-type(even) {
  background-color: #efefef;
}
tbody tr:last-of-type {
  border-bottom: 3px solid #f27300;
}

.highlight,
.highlight:nth-last-of-type(even) {
  background-color: #f27300 !important;
}

@media screen and (max-width: 1024px) {
  th,
  td {
    padding: 0.4rem 0.6rem;
    font-size: 0.8em;
  }
}
</style>
