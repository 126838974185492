<template>
  <div class="p-container">
    <div class="pagination">
      <button @click="first">&laquo;</button>
      <button v-show="currentPage > 1" @click="prev">
        {{ currentPage - 1 }}
      </button>
      <button class="active">
        {{ currentPage }}
      </button>
      <button v-show="currentPage < lastPage" @click="next">
        {{ currentPage + 1 }}
      </button>
      <button @click="last">&raquo;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentPage: Number,
    next: Function,
    prev: Function,
    first: Function,
    last: Function,
    lastPage: Number,
  },
};
</script>

<style scoped>
.p-container {
  text-align: center;
}
.p-container p {
  color: #fff;
  display: inline;
  padding: 0.4rem;
}

.pagination {
  display: inline;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
}
.pagination button.active {
  background-color: #f27300;
  color: white;
  border: 1px solid #f27300;
}
.pagination button:hover:not(.active) {
  background-color: #ddd;
}
.pagination button:first-child {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}

.pagination button:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
</style>
