<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Delete Student</h1>
      </div>
      <form @submit.prevent="deleteStudent">
        <div style="padding: 12px; font-size: 22px">
          <p>Are you sure you want to delete?</p>
        </div>
        <FilledButton text="Delete" />
      </form>
    </div>
  </div>
</template>

<script>
import FilledButton from "../FilledButton.vue";

export default {
  name: "DeleteStudent",
  components: {
    FilledButton,
  },
  props: { username: String },
  methods: {
    async deleteStudent() {
      const res = await fetch(
        process.env.VUE_APP_URL + "students/" + this.username,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "application/json",
          },
          method: "DELETE",
        },
      );
      const body = await res.json();
      if (res.status !== 200) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
  },
};
</script>
