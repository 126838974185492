<template>
  <router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  font-size: 16px;
}
</style>
