<template>
  <div class="title">
    <h1>Schools:</h1>
  </div>
  <div class="filled">
    <FilledButton text="Add" @click="() => (popupShow = true)" />
    <FilledButton text="Update" @click="update" />
    <FilledButton
      text="Delete"
      @click="() => (selectedId > 0 ? (deletePopup = true) : null)"
    />
    <FilledButton
      text="Upload"
      @click="() => (schoolId != -1 ? (uploadPopup = true) : null)"
    />
    <FilledButton text="Download" @click="download()" />
  </div>

  <AddSchool
    @close="
      () => {
        popupShow = false;
        getData();
      }
    "
    v-show="popupShow"
  />
  <DeleteSchool
    :id="selectedId"
    @close="
      () => {
        deletePopup = false;
        getData();
      }
    "
    v-show="deletePopup"
  />
  <UpdateSchool
    :id="selectedId"
    :schoolData="selectedData"
    @close="
      () => {
        updatePopup = false;
        getData();
      }
    "
    v-show="updatePopup"
  />
  <Upload
    :url="url"
    @close="
      () => {
        uploadPopup = false;
        getData(schoolId);
      }
    "
    v-show="uploadPopup"
  />

  <Table @selected="selected" :columns="columns" :entries="entries" />
  <Pagination
    :currentPage="currentPage"
    :next="next"
    :prev="prev"
    :last="last"
    :first="first"
    :lastPage="lastPage"
  />
</template>

<script>
import FilledButton from "../FilledButton.vue";
import Table from "./SchoolsTable.vue";
import Pagination from "../Pagination.vue";
import AddSchool from "./AddSchool.vue";
import DeleteSchool from "./DeleteSchool.vue";
import UpdateSchool from "./UpdateSchool.vue";
import Upload from "../Upload.vue";

export default {
  name: "Students",
  components: {
    FilledButton,
    Table,
    Pagination,
    AddSchool,
    DeleteSchool,
    UpdateSchool,
    Upload,
  },
  data: () => {
    return {
      columns: [
        { name: "id", text: "ID" },
        { name: "name", text: "Name" },
        { name: "username", text: "Username" },
        { name: "password", text: "Password" },
      ],
      entries: [],
      currentPage: 1,
      lastPage: 1,
      selectedId: -1,
      popupShow: false,
      deletePopup: false,
      updatePopup: false,
      uploadPopup: false,
      selectedData: {},
      url: process.env.VUE_APP_URL + "schools/upload/xlsx",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getAllSchools() {
      const res = await fetch(
        process.env.VUE_APP_URL + "schools?page=" + this.currentPage,
        { headers: { Authorization: `Bearer ${localStorage.token}` } },
      );
      let body;
      if (res.ok) body = await res.json();
      return body;
    },
    next() {
      if (this.currentPage < this.lastPage) this.currentPage++;
      this.getData();
    },
    prev() {
      if (this.currentPage > 1) this.currentPage--;
      this.getData();
    },
    first() {
      this.currentPage = 1;
      this.getData();
    },
    last() {
      this.currentPage = this.lastPage;
      this.getData();
    },
    getData() {
      this.getAllSchools().then((res) => {
        this.entries = res.value;
        this.lastPage = res.maxPage;
      });
    },
    selected(id) {
      this.selectedId = id;
    },
    async update() {
      const result = await this.entries.find((x) => x.id === this.selectedId);
      if (result) {
        this.selectedData = result;
        this.updatePopup = true;
      }
    },

    async download() {
      await fetch(`${process.env.VUE_APP_URL}schools/download/xlsx`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const newBlob = new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const a = document.createElement("a");
          const blobURL = URL.createObjectURL(newBlob);
          a.download = "schools";
          a.href = blobURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a).xlsx;
        });
    },
  },
};
</script>

<style scoped>
.title {
  color: #fff;
  margin-top: 0.8rem;
  padding: 0.7rem;
}

span {
  color: #f27300;
  white-space: nowrap;
}
.filled {
  padding: 0.7rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
</style>
