import { createRouter, createWebHashHistory } from "vue-router";
import parseJwt from "../helper/parseJwt";
import Login from "../components/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Students from "../components/Students/Students.vue";
import Schools from "../components/Schools/Schools.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { requireAuth: false },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requireAuth: true },
    children: [
      { path: "", component: Students },
      { path: "students", component: Students },
      { path: "schools", name: "Schools", component: Schools },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const haveToken = localStorage.getItem("token") !== null;
  const jwtPayload = haveToken && parseJwt(localStorage.token);

  if (to.meta.requireAuth) {
    if (!haveToken || jwtPayload.exp < Date.now() / 1000) {
      localStorage.removeItem("token");
      next({ name: "Login" });
    } else {
      if (to.name === "Schools" && jwtPayload.role !== "admin") next("/404");
      else if (to.name === "Login") next({ name: "Dashboard" });
      else next();
    }
  } else {
    next();
  }
});

export default router;
