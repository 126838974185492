<template>
  <select
    :disabled="this.data.length === 0"
    @change="getSelected($event)"
    :class="{ bg: this.selected.id != -1, 'read-only': this.data.length === 0 }"
  >
    <option
      v-for="option in optionData"
      :key="option"
      :value="option.id"
      :selected="option.id == -1"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    data: Array,
    name: String,
  },
  data: function () {
    return {
      selected: { id: -1, name: this.name },
    };
  },
  methods: {
    getSelected(e) {
      this.selected.id = e.target.value;
      this.$emit("selectedOption", e.target.value);
    },
  },
  computed: {
    optionData() {
      const dataArray = [{ id: -1, name: this.name }, ...(this.data || [])];
      return dataArray;
    },
  },
};
</script>

<style scoped>
select {
  padding: 0.8rem;
  font-size: 1.1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  appearance: none;
}
select:active {
  border: none;
  outline: none;
}
select option {
  background-color: #fff;
  color: #000;
}

.bg {
  background-color: #f27300;
  color: #fff;
}

.read-only {
  background-color: #000;
  color: #f27300;
  font-size: 1.6rem;
}

@media screen and (max-width: 1024px) {
  select {
    padding: 0.6rem;
    font-size: 1rem;
  }
}
</style>
