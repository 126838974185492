<template>
  <div class="title">
    <h1>
      Students:/
      <Dropdown
        @selectedOption="selectedOption"
        :data="schools"
        :name="schoolName"
      />
    </h1>
  </div>
  <div class="buttons">
    <div class="dropdown">
      <!--Dropdown :data="options" name="Grade" /-->
    </div>
    <FilledButton
      v-show="userData().role === 'admin'"
      @click="schoolId !== -1 ? togglePopup() : null"
      text="Add"
    />
    <FilledButton
      v-show="userData().role === 'admin'"
      text="Update"
      @click="update"
    />
    <FilledButton
      v-show="userData().role === 'admin'"
      text="Delete"
      @click="() => (selectedId != -1 ? (deletePopup = true) : null)"
    />
    <FilledButton
      v-show="userData().role === 'admin'"
      text="Upload"
      @click="() => (schoolId != -1 ? (uploadPopup = true) : null)"
    />
    <!--FilledButton text="Download" @click="download()" /-->
  </div>
  <AddStudent
    @close="
      () => {
        popupShow = false;
        getData(schoolId);
      }
    "
    :school_id="parseInt(schoolId)"
    v-show="popupShow"
  />

  <UpdateStudent
    :studentData="selectedData"
    @close="
      () => {
        updatePopup = false;
        getData(schoolId);
      }
    "
    v-show="updatePopup"
  />

  <DeleteStudent
    :username="String(selectedId)"
    @close="
      () => {
        deletePopup = false;
        getData(schoolId);
      }
    "
    v-show="deletePopup"
  />

  <Upload
    :url="url"
    @close="
      () => {
        uploadPopup = false;
        getData(schoolId);
      }
    "
    v-show="uploadPopup"
  />

  <Table @selected="selected" :columns="columns" :entries="entries" />
  <Pagination :currentPage="currentPage" />
</template>

<script>
import Dropdown from "../Dropdown.vue";
import FilledButton from "../FilledButton.vue";
import Table from "./StudentsTable.vue";
import Pagination from "../Pagination.vue";
import parseJwt from "../../helper/parseJwt";
import AddStudent from "./AddStudent.vue";
import UpdateStudent from "./UpdateStudent.vue";
import DeleteStudent from "./DeleteStudent.vue";
import Upload from "../Upload.vue";

export default {
  name: "Students",
  components: {
    Dropdown,
    FilledButton,
    Table,
    Pagination,
    AddStudent,
    UpdateStudent,
    DeleteStudent,
    Upload,
  },
  data: () => {
    return {
      options: [{ name: "KG1" }, { name: "KG2" }],
      columns: [
        { name: "first_name", text: "First Name" },
        { name: "last_name", text: "Last Name" },
        { name: "grad", text: "Subject 1" },
        { name: "password", text: "Subject 2" },
        { name: "score", text: "Score" },
      ],
      entries: [],
      schools: [],
      currentPage: 1,
      lastPage: 1,
      selectedId: -1,
      popupShow: false,
      deletePopup: false,
      updatePopup: false,
      uploadPopup: false,
      schoolName: "School Name",
      schoolId: -1,
      selectedData: {},
      url: "",
    };
  },
  created() {
    const id = this.userData().id;

    if (id) {
      this.getData(id);
      this.schoolName = this.userData().username;
    } else {
      this.getSchoolsData();
    }
  },
  methods: {
    userData() {
      const jwtData = parseJwt(localStorage.token);
      return {
        username: jwtData.username,
        role: jwtData.role,
        id: jwtData?.id,
      };
    },

    async download() {
      if (this.schoolId === -1) return;
      await fetch(
        `${process.env.VUE_APP_URL}students/${this.schoolId}/download/xlsx`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
        .then((res) => res.blob())
        .then((blob) => {
          const newBlob = new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const a = document.createElement("a");
          const blobURL = URL.createObjectURL(newBlob);
          a.download = "students";
          a.href = blobURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a).xlsx;
        });
    },

    async getAllStudents(school_id) {
      const res = await fetch(
        `${process.env.VUE_APP_URL}students/${school_id}?page=${this.currentPage}`,
        { headers: { Authorization: `Bearer ${localStorage.token}` } }
      );
      let body;
      if (res.ok) body = await res.json();
      return body;
    },
    async getSchoolsNameId() {
      const res = await fetch(process.env.VUE_APP_URL + "schools/all/name_id", {
        headers: { Authorization: `Bearer ${localStorage.token}` },
      });
      let body;
      if (res.ok) body = await res.json();
      return body;
    },
    next() {
      if (this.currentPage < this.lastPage) this.currentPage++;
      getData(this.schoolId);
    },
    prev() {
      if (this.currentPage > 1) this.currentPage--;
      getData(this.schoolId);
    },
    getData(school_id) {
      this.getAllStudents(school_id).then((res) => {
        this.entries = res.value;
        this.lastPage = res.maxPage;
      });
    },
    getSchoolsData() {
      this.getSchoolsNameId().then((res) => {
        this.schools = res.value;
      });
    },
    selected(id) {
      this.selectedId = id;
    },

    togglePopup() {
      this.popupShow = !this.popupShow;
    },

    selectedOption(id) {
      if (id > 0) {
        this.schoolId = id;
        this.url =
          process.env.VUE_APP_URL +
          "students/" +
          this.schoolId +
          "/upload/xlsx";
        this.getData(id);
      } else {
        this.entries = [];
      }
    },
    async update() {
      const result = await this.entries.find(
        (x) => x.username === this.selectedId
      );
      if (result) {
        this.selectedData = result;
        this.updatePopup = true;
      }
    },
  },
};
</script>

<style scoped>
.title {
  color: #fff;
  margin-top: 0.8rem;
  padding: 0.7rem;
}

span {
  color: #f27300;
  white-space: nowrap;
}
.buttons {
  padding: 0.7rem;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.dropdown {
  display: flex;
  width: 22%;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .title h1 {
    font-size: 1.5rem;
  }
}
</style>
