<template>
  <button :class="text">{{ text }}</button>
</template>

<script>
export default {
  name: "FilledButton",
  props: {
    text: String,
  },
};
</script>

<style scoped>
button {
  border: 1px solid white;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  padding: 0.4rem 1rem;
  cursor: pointer;
  font-family: inherit;
  border: none;
}
button:active {
  transform: scale(0.96);
}

.Logout {
  background: #f27300;
}
.Add {
  background: #008000;
  color: #fff;
}
.Update {
  background: #0000ff;
  color: #fff;
}
.Delete {
  background: #ff0000;
  color: #fff;
}
.Download {
  background: #00aaff;
  color: #fff;
}
.Upload {
  background: #aa00ff;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  button {
    font-size: 1.1rem;
  }
}
</style>
