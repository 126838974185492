<template>
  <div class="popup">
    <div class="popup-content">
      <div class="herder">
        <span class="close" @click="() => this.$emit('close')">&times;</span>
        <h1>Add Student</h1>
      </div>
      <form @submit.prevent="add">
        <div>
          <Input :data="formData.first_name" :validationType="'string'" />
          <Input :data="formData.last_name" :validationType="'string'" />
          <Input :data="formData.grade" :validationType="'string'" />
          <Input :data="formData.score" :validationType="'string'" />
          <Input :data="formData.username" :validationType="'string'" />
          <Input :data="formData.password" :validationType="'string'" />
        </div>
        <FilledButton text="Add" />
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import FilledButton from "../FilledButton.vue";
import Input from "../Input.vue";

export default {
  name: "AddStudent",
  components: {
    FilledButton,
    Input,
  },
  props: {
    school_id: Number,
  },
  data: () => {
    const formData = reactive({
      first_name: {
        data: "",
        label: "First Name",
      },
      last_name: {
        data: "",
        label: "Last Name",
      },
      grade: {
        data: "",
        label: "Grade",
      },
      score: {
        data: "",
        label: "Score",
      },
      username: {
        data: "",
        label: "Username",
      },
      password: {
        data: "",
        label: "Password",
      },
    });

    return {
      formData,
    };
  },
  methods: {
    async add() {
      const data = {
        first_name: this.formData.first_name.data,
        last_name: this.formData.last_name.data,
        grade: this.formData.grade.data,
        score: this.formData.score.data,
        username: this.formData.username.data,
        password: this.formData.password.data,
        school_id: this.school_id,
      };
      const res = await fetch(process.env.VUE_APP_URL + "students/", {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const body = await res.json();
      if (res.status !== 201) this.$toast.error(body.ERROR);
      else {
        this.$toast.success(body.message);
      }
      this.$emit("close");
    },
  },
};
</script>

<style>
.popup {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding: 5rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  box-sizing: border-box;
}

/* Modal Content */
.popup-content {
  background-color: #fff;
  margin: auto;
  padding: 1.3rem;
  border: 2px solid #f27300;
  border-radius: 0.6rem;
  width: 50%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 1.7rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.popup-content form {
  text-align: center;
}
.popup-content .dropdown {
  width: 54%;
  background-color: #f27300;
  margin-block: 1.2rem;
}

FilledButton {
  margin-top: 30px;
}
</style>
